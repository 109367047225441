<!--
 * @Author: Libra
 * @Date: 2022-03-15 18:04:12
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/confirm/exam/index.vue
-->
<template>
  <layout>
      <div
        slot="content"
        class="section exam"
      >
      <GlobalDialog
          :dialogVisible="showQuestionDelay"
          @dialog-cancel="toQuestion"
          title="试卷发放"
          :show_close="false"
          :isShowFooter="false"
          width="560px"
        >
          <div class="start-exam-container">
            <div class="distribution">
              <i class="iconfont iconICAtubiao_huodongdaojishi-copy"></i>
              <div class="right shunt">
                <span
class="title"
                  ><span style="font-size: 24px;">发卷中</span
                  >，请耐心等待</span
                >
                <span
class="time"
v-if="false"
                  >倒计时<span class="time-detail">{{
                    `00:00:${String(randomTimeDelay).padStart(2, '0')}`
                  }}</span></span
                >
              </div>
            </div>
          </div>
        </GlobalDialog>
      <GlobalDialog
          :dialogVisible="showPartNotice"
          title="面试须知"
          :show_close="false"
          :isShowFooter="false"
          :showModal="true"
          width="80%"
        >
          <div class="interview">
            <div class="record-title">
              <span class="title-text"><i class="iconfont iconjishi1"></i> 进入面试答题倒计时：</span>
              <span class="time">{{ formatSeconds(partStartTime) }}</span>
            </div>
            <span v-html="strReplace(partNote)"></span>
          </div>
        </GlobalDialog>
        <GlobalDialog
          :dialogVisible="showConfirmSubmit"
          @dialog-cancel="showConfirmSubmit = false"
          @dialog-ok="showConfirmSubmit = false"
          title="提示"
          width="500px"
          :showSecond="true"
          btn_title="取消"
          btn_title2="确认"
        >
          <div class="occupy-result">
            <i class="iconfont icontishi"></i>
            <div class="right">
              <span class="detail">你确认要提交吗？</span>
            </div>
          </div>
        </GlobalDialog>
        <GlobalDialog
          :dialogVisible="showMustAnswer"
          @dialog-cancel="showMustAnswer = false"
          title="提示"
          width="500px"
        >
          <div class="occupy-result">
            <i class="iconfont icontishi"></i>
            <div class="right">
              <span
class="detail"
                >算法基本部分 试卷为必答卷，<br />请完成作答后，再交卷！</span
              >
            </div>
          </div>
        </GlobalDialog>
        <GlobalDialog
          :dialogVisible="showFiveMinutes"
          @dialog-cancel="showFiveMinutes = false"
          title="提示"
          width="500px"
          :show_close="false"
        >
          <div class="occupy-result">
            <i class="iconfont icontishi"></i>
            <div class="right">
              <span
class="detail"
                >开考{{
                  $store.state.examInfo.allowSubmitSecond / 60
                }}分钟之内，不允许交卷！</span
              >
            </div>
          </div>
        </GlobalDialog>
        <GlobalDialog
          :dialogVisible="showNotAnwserTip"
          @dialog-cancel="closeSubmitDialog"
          @close="showNotAnwserTip = false"
          @dialog-ok="finish"
          title="提示"
          width="500px"
          :show_close="false"
          :showSecond="true"
          :disabled2="subTime !== 0"
          btn_title="取消"
          :btn_title2="`确认${subTime || ''}`"
        >
          <div class="occupy-result">
            <i class="iconfont icontishi"></i>
            <div class="right">
              <div class="title">您还有未作答的题目!</div>
              <!-- <div class="detail red">交卷后无法再次登录考试系统</div> -->
              <div class="detail">您确认要交卷吗?</div>
            </div>
          </div>
        </GlobalDialog>
        <GlobalDialog
          :dialogVisible="showConfirmSubTip"
          @dialog-cancel="closeSubmitDialog"
          @close="showConfirmSubTip = false"
          @dialog-ok="finish"
          title="提示"
          width="500px"
          :show_close="false"
          :showSecond="true"
          :disabled2="subTime !== 0"
          btn_title="取消"
          :btn_title2="`确认${subTime || ''}`"
        >
          <div class="occupy-result">
            <i class="iconfont icontishi"></i>
            <div class="right">
              <!-- <div class="title red">交卷后无法再次登录考试系统</div> -->
              <div class="detail">您确认要交卷吗?</div>
            </div>
          </div>
        </GlobalDialog>
        <detect-layout />
        <div class="title">
          试卷列表 {{ isPractice ? '（考前练习题）' : '' }}
        </div>
        <!-- <div class="tips"><i class="el-icon-warning"></i> 提交后不能修改</div> -->
        <div
class="paper-list"
v-loading="loading"
        element-loading-text="试卷列表加载中">
          <div
            class="paper-item"
            v-for="(paper, key) in papers"
            :key="paper.positionUuid"
          >
            <div class="info">
              <div class="name">
                卷 {{ key + 1 }}：{{ paper.name }}&nbsp;&nbsp;{{
                  $store.state.jobInfo.partStartTimeType === 2
                    ? paper.startAt
                      ? `（开始时间：${paper.startAt.slice(-8)}）`
                      : ''
                    : ''
                }}
              </div>
              <div class="attr">
                <span
                  >试题：<span
style="font-size: 17px;color: #333;"
                    >{{ paper.questionCount }} </span
                  ><span style="font-size: 14px;">道</span></span
                >
                <span
                  >已答：<span
style="font-size: 20px;color: #333;"
                    >{{ isPractice ? '--' : paper.answeredCount || 0 }} </span
                  ><span style="font-size: 14px;">道</span></span
                >
                <span
                  >时长：<span
style="font-size: 17px;color: #333;"
                    >{{
                      isPractice ? '--' : Math.ceil(paper.partDuration / 60)
                    }} </span
                  ><span style="font-size: 14px;">分钟</span></span
                >
                <span
v-if="!$store.state.examInfo.isEvaluation"
                  >分值：<span
style="font-size: 17px;color: #333;"
                    >{{
                      isPractice || paper.isQuestionnaire
                        ? '--'
                        : paper.partScore
                    }} </span
                  ><span style="font-size: 14px;">分</span></span
                >
                <span>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="提交后不能修改"
                    placement="bottom"
                  >
                    <i
                      v-if="!paper.isEnableModifyAfterSubmit && !isPractice"
                      class="iconfont iconNo_editor"
                    ></i>
                  </el-tooltip>
                </span>
              </div>
            </div>
            <!-- NOT_START(0, "子卷未开始"),
              STARTED(1, "子卷开始"),
              ANSWERED(2, "已经答题"),
              SUBMIT(3, "已经提交"), -->
            <el-button
              v-if="paper.status === 0"
              class="operate-btn"
              type="primary"
              icon="el-icon-edit"
              @click="startPart(paper)"
              >开始答题</el-button
            >
            <el-button
              v-else-if="paper.status === 1"
              class="operate-btn"
              type="primary"
              icon="el-icon-edit"
              @click="toQuestion(paper)"
              >已开始</el-button
            >
            <el-button
              v-else-if="paper.status === 3"
              type="info"
              :style="
                !paper.isEnableModifyAfterSubmit
                  ? 'width: 118px;background-color: #b5b5b5; color: #fff'
                  : 'width: 118px;background-color: rgba(203, 42, 29, 0.2); color: #cb2a1d'
              "
              plain
              @click="toQuestion(paper)"
              :disabled="!paper.isEnableModifyAfterSubmit"
              :icon="
                !paper.isEnableModifyAfterSubmit
                  ? 'iconfont iconright-f'
                  : 'iconfont iconxiangpi'
              "
              >{{
                !paper.isEnableModifyAfterSubmit ? '已提交' : '修改答案'
              }}</el-button
            >
            <el-button
              v-else-if="paper.status === 2"
              class="operate-btn"
              type="primary"
              icon="iconfont iconxiangpi"
              @click="toQuestion(paper)"
              >修改答案</el-button
            >
            <el-button
              v-else
              class="operate-btn"
              type="primary"
              icon="iconfont iconxiangpi"
              @click="toPracticeQuestion(paper)"
              >开始答题</el-button
            >
          </div>
        </div>
        <el-button
          v-if="!isPractice"
          v-preventReClick
          class="sub-btn"
          type="primary"
          @click="examFinish()"
          >{{ hasNextExam ? '交卷并作答下一场' : '我要交卷' }}</el-button
        >
        <el-button
          v-if="isPractice"
          class="sub-btn"
          type="primary"
          @click="() => $router.push({ path: '/notice' })"
          >返回</el-button
        >
        <!-- <span class="exam-tips" v-html="strReplace(partNote)"></span> -->
      </div>
    </layout>
</template>

<script>
/**
 *
  下面是 抓拍和人脸识别的一些规则， 涉及文件：当前组件和 question/components/Layout组件
  考试过程中，所有考生分三个时间段，把所有考生随机人脸识别比对一遍，仅跟之前活体采样的照片比对，不要跟公安部比对。比对一次1毛。
  抓拍照片规则：
  1，第一次抓拍：每个人的开始作答时刻，即在分流的弹窗，等分流结束后，点击弹窗按钮下方开始作答按钮的时刻；
  2，后续抓拍：初始抓拍+固定间隔（5分钟）

  人脸识别规则：以试卷时长20min分界点做判断，单人单场考试最多调用3次
  1，如果试卷时长t<=20，将试卷时长三等分，在三个区间内，前端随机调用接口
  2，如果试卷时长t>20，去除首尾（5分钟）密集区，将剩余时长（t-5-5）三等分，在三个区间内，前端随机调用接口
  3，特别的：第一次为随机时刻，后面两次调用的时刻为 第一次调用时刻+固定间隔（三等分时长）
 */
import Layout from '@/views/confirm/layout'
import GlobalDialog from '@/components/GlobalDialog'
import DetectLayout from '@/views/components/DetectLayout'
import Api from '@/api/api'
import store from '@/store'
import { bus } from '@/utils/bus'
import { getRandom, formatSeconds } from '@/utils/common'
import api from '../../../api/api'

export default {
  name: 'ConfirmExam',
  components: {
    Layout,
    GlobalDialog,
    DetectLayout
  },
  data() {
    return {
      papers: [],
      dialogVisible: false,
      showConfirmSubmit: false,
      showMustAnswer: false,
      showFiveMinutes: false,
      showNotAnwserTip: false,
      showConfirmSubTip: false,
      showPartNotice: false,
      subTime: 5,
      isPractice: this.$route.query.isPractice,
      subCountTimer: null,
      isBlur: true,
      partNote: '',
      mobileMonitor: 0,
      loading: true,
      retryCount: 2,
      // 是否还有下一场考试
      hasNextExam: !!this.$store.state.examInfo.nextExamUuid,
      cameraMonitor: this.$store.state.examInfo.cameraMonitorType === 0,
      screenShotMonitorType:
        this.$store.state.examInfo.screenShotMonitorType || 0,
      randomTimeDelay: -1,
      showQuestionDelay: false,
      partStartTime: 0,
      questionDelayTimer: null
    }
  },
  async mounted() {
    this.isPractice
      ? await this.getPraticePaperList()
      : await this.getPaperList()
    this.loading = false
    const { mobileMonitorType, mobileVideoType } = this.$store.state.examInfo
    this.mobileMonitor = mobileMonitorType === 0 && mobileVideoType === 0
    // this.partNote = this.$store.state.examInfo.partNote || ''
    this.initBlur()
  },
  methods: {
    formatSeconds(time) {
      return formatSeconds(time)
    },
    async handleInterviewPartTime(paper) {
      const startTime = new Date(this.$store.state.examInfo.startAt).getTime()
      this.partStartTime = paper.readDuration
      this.partNote = paper.partNote
      let curTime = null
      // 当前时间
      const res = await Api.getTime()
      if (res.code === 0) {
        curTime = res.data.timestamp * 1000
        // 测试 startTime + 5分钟
        // curTime = startTime + 1 * 60 * 1000
      }
      const diffTime = curTime - startTime
      if (diffTime > this.partStartTime * 1000) {
        this.isNeedPartDelay(paper)
        return
      } else {
        this.partStartTime = this.partStartTime - Math.floor(diffTime / 1000)
        this.showPartNotice = true
      }
      const timer = setInterval(() => {
        if (this.partStartTime > 0) {
          this.partStartTime--
        } else {
          clearInterval(timer)
          this.isNeedPartDelay(paper)
        }
      }, 1000)
    },
    timeDifference(timestamp, dateString) {
      // Convert the timestamp to a date object
      const timestampDate = new Date(timestamp)
      // Convert the date string to a date object
      const dateStringDate = new Date(dateString)
      // Calculate the difference in milliseconds
      const difference = dateStringDate.getTime() - timestampDate.getTime()
      // Return the difference in milliseconds
      return Math.floor(difference / (1000 * 60))
    },
    async startPart(paper) {
      // 如果是人机面试，则需要弹窗弹出子卷须知
      if (paper.type === 3) {
        await this.handleInterviewPartTime(paper)
      } else {
        this.isNeedPartDelay(paper)
      }
    },
    // 是否需要小卷分流
    async isNeedPartDelay(paper) {
      if (this.$store.state.jobInfo.partStartTimeType === 2) {
        const res = await api.getTime()
        if (res.code === 0) {
          const delay = this.timeDifference(Number(res.data.timestamp) * 1000, paper.startAt)
          if (delay < 0 && delay >= -2) {
            this.toQuestionDelay(paper)
          } else {
            this.toQuestion(paper)
          }
        }
      } else {
        this.toQuestion(paper)
      }
    },
    closeSubmitDialog() {
      this.showNotAnwserTip = false
      this.showConfirmSubTip = false
      clearInterval(this.subCountTimer)
      const t = setTimeout(() => {
        this.subTime = 5
        clearTimeout(t)
      }, 300)
    },
    // 交卷倒计时
    subCountDown() {
      this.subCountTimer = setInterval(() => {
        if (this.subTime > 0) {
          this.subTime--
        } else {
          clearInterval(this.subCountTimer)
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.subCountTimer)
      })
    },
    async getPaperList() {
      this.retryCount--
      if (this.retryCount <= 0) {
        this.loading = false
        this.$message.error('获取试卷失败,请点击右上角退出，重新登录！')
        return
      }
      const res = await Api.paperList()
      if (res.code === 30014) {
        // 重新start
        await Api.examStart()
        setTimeout(() => {
          this.getPaperList()
        }, 2000)
      } else if (res.code === 30063) {
        // 重新领卷
        setTimeout(() => {
          this.getPaperList()
        }, 2000)
      } else if (res.code === 0) {
        this.papers = res.data
        this.loading = false
      }
    },
    async getPraticePaperList() {
      const res = await Api.paperPraticeList()
      if (res.code === 0) {
        this.papers = res.data
        this.loading = false
      }
    },

    // 去答题页面
    async toPracticeQuestion(paper) {
      const {
        questionIdList,
        partUuid,
        name,
        isOptionRandom,
        isQuestionnaire,
        isEnablePreviousQuestion,
        readDuration
      } = paper
      this.$router.push({
        path: '/question',
        query: {
          questionIdList,
          partUuid,
          isOptionRandom,
          isQuestionnaire,
          isEnablePreviousQuestion,
          name,
          current: 0,
          isPractice: true,
          readDuration
        }
      })
    },
    async toQuestionDelay(paper) {
      clearInterval(this.questionDelayTimer)
      let randomTime = getRandom(1, 15)
      this.showQuestionDelay = true
      this.questionDelayTimer = setInterval(() => {
        randomTime = randomTime - 1
        this.randomTimeDelay = randomTime
        if (randomTime === 0) {
          this.toQuestion(paper)
          clearInterval(this.questionDelayTimer)
        }
      }, 1000)
    },
    // 去答题页面
    async toQuestion(paper) {
      if (paper.status === 3 && !paper.isEnableModifyAfterSubmit) {
        return
      }
      // 如果启用了屏幕录屏或者屏幕监拍，但是用户没有打开屏幕共享，不允许用户进入小卷
      if (this.$store.state.examInfo.screenShotMonitorType === 1) {
        if (!this.$store.state.pcDetect) {
          this.$message.error('请先允许屏幕共享！')
          bus.$emit('screenShare')
          return
        }
      }
      const {
        questionIdList,
        partUuid,
        name,
        isQuestionnaire,
        isOptionRandom,
        isEnablePreviousQuestion,
        readDuration
      } = paper
      const res = await Api.partEnter(partUuid)
      if (res.code === 0) {
        this.$router.push({
          path: '/question',
          query: {
            questionIdList,
            partUuid,
            isQuestionnaire,
            isOptionRandom,
            isEnablePreviousQuestion,
            name,
            current: 0,
            readDuration
          }
        })
      } else {
        console.log('开始答题失败！！')
      }
    },
    // 初始化blur事件
    initBlur() {
      this.removeBlur()
      let timer = null
      // 监听浏览器的焦点事件,如果离开当前页面超过五秒钟，那么就会进行一次抓拍
      window.onblur = () => {
        let count = 1
        if (document.activeElement.tagName === 'IFRAME') return
        timer = window.setInterval(async() => {
          if (count >= 5) {
            clearInterval(timer)
            timer = null
            timer = window.setInterval(async() => {
              this.isBlur = false
              if (this.screenShotMonitorType === 0 || this.isPractice) {
                return
              } else {
                count += 1
                // this.$children[0].browserSnap(true)
              }
            }, 30000)
          }
          this.isBlur = false
          if (this.screenShotMonitorType === 0 || this.isPractice) {
            return
          } else {
            count += 1
            // this.$children[0].browserSnap(true)
          }
        }, 5000)
      }
      this.removeFocus()
      window.onfocus = () => {
        clearInterval(timer)
        if (!this.isBlur) {
          this.isBlur = true
        }
      }
    },
    // 移除事件的handler 为避免重复添加，在所有事件添加之前，都进行移除。
    eventRemoveHandler() {
      console.log('event removed...')
    },
    //  移除 focus 事件
    removeFocus() {
      window.removeEventListener('focus', this.eventRemoveHandler)
    },
    //  移除 blur 事件
    removeBlur() {
      window.removeEventListener('blur', this.eventRemoveHandler)
    },
    // 移除所有事件监听
    removeAllEvent() {
      this.removeBlur()
      this.removeFocus()
    },
    // 进入下一场考试
    async nextExam() {
      const res = await Api.nextLogin()
      if (res.code === 0) {
        store.commit('setToken', res.data)
        this.$router.push('/basic')
        // 进入下一场考试会导致监控失效
        // 暂时先刷新一下页面解决
        this.$router.go(0)
      }
    },
    // 考试结束
    async examFinish() {
      const paper = await Api.paperList()
      let allAnswered = true
      if (paper.code === 0) {
        const resData = paper.data
        for (const item of resData) {
          if (item.isMustAnswer && item.status !== 3) {
            this.$message.error(`${item.name}小卷为必答卷，请作答`)
            return
          }
          if (item.questionCount !== item.answeredCount) {
            allAnswered = false
          }
        }
      }
      if (!allAnswered) {
        this.showNotAnwserTip = true
        this.subCountDown()
      } else {
        // await this.finish()
        this.showConfirmSubTip = true
        this.subCountDown()
      }
    },
    // 考试结束接口
    async finish() {
      this.showNotAnwserTip = false
      this.showConfirmSubTip = false
      const res = await Api.examFinish()
      if (res.code === 30026) {
        this.showFiveMinutes = true
        return
      }
      if (res.code === 0) {
        if (this.hasNextExam) {
          await this.nextExam()
        } else {
          this.$router.push({
            path: '/complete'
          })
        }
      } else {
        console.log('提交失败！！')
      }
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
@import './index.scss';
</style>
