var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("layout", [
    _c(
      "div",
      {
        staticClass: "section exam",
        attrs: { slot: "content" },
        slot: "content",
      },
      [
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showQuestionDelay,
              title: "试卷发放",
              show_close: false,
              isShowFooter: false,
              width: "560px",
            },
            on: { "dialog-cancel": _vm.toQuestion },
          },
          [
            _c("div", { staticClass: "start-exam-container" }, [
              _c("div", { staticClass: "distribution" }, [
                _c("i", {
                  staticClass: "iconfont iconICAtubiao_huodongdaojishi-copy",
                }),
                _c("div", { staticClass: "right shunt" }, [
                  _c("span", { staticClass: "title" }, [
                    _c("span", { staticStyle: { "font-size": "24px" } }, [
                      _vm._v("发卷中"),
                    ]),
                    _vm._v("，请耐心等待"),
                  ]),
                  false
                    ? _c("span", { staticClass: "time" }, [
                        _vm._v("倒计时"),
                        _c("span", { staticClass: "time-detail" }, [
                          _vm._v(
                            _vm._s(
                              `00:00:${String(_vm.randomTimeDelay).padStart(
                                2,
                                "0"
                              )}`
                            )
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showPartNotice,
              title: "面试须知",
              show_close: false,
              isShowFooter: false,
              showModal: true,
              width: "80%",
            },
          },
          [
            _c("div", { staticClass: "interview" }, [
              _c("div", { staticClass: "record-title" }, [
                _c("span", { staticClass: "title-text" }, [
                  _c("i", { staticClass: "iconfont iconjishi1" }),
                  _vm._v(" 进入面试答题倒计时："),
                ]),
                _c("span", { staticClass: "time" }, [
                  _vm._v(_vm._s(_vm.formatSeconds(_vm.partStartTime))),
                ]),
              ]),
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.strReplace(_vm.partNote)) },
              }),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showConfirmSubmit,
              title: "提示",
              width: "500px",
              showSecond: true,
              btn_title: "取消",
              btn_title2: "确认",
            },
            on: {
              "dialog-cancel": function ($event) {
                _vm.showConfirmSubmit = false
              },
              "dialog-ok": function ($event) {
                _vm.showConfirmSubmit = false
              },
            },
          },
          [
            _c("div", { staticClass: "occupy-result" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "detail" }, [
                  _vm._v("你确认要提交吗？"),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showMustAnswer,
              title: "提示",
              width: "500px",
            },
            on: {
              "dialog-cancel": function ($event) {
                _vm.showMustAnswer = false
              },
            },
          },
          [
            _c("div", { staticClass: "occupy-result" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "detail" }, [
                  _vm._v("算法基本部分 试卷为必答卷，"),
                  _c("br"),
                  _vm._v("请完成作答后，再交卷！"),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showFiveMinutes,
              title: "提示",
              width: "500px",
              show_close: false,
            },
            on: {
              "dialog-cancel": function ($event) {
                _vm.showFiveMinutes = false
              },
            },
          },
          [
            _c("div", { staticClass: "occupy-result" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "detail" }, [
                  _vm._v(
                    "开考" +
                      _vm._s(_vm.$store.state.examInfo.allowSubmitSecond / 60) +
                      "分钟之内，不允许交卷！"
                  ),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showNotAnwserTip,
              title: "提示",
              width: "500px",
              show_close: false,
              showSecond: true,
              disabled2: _vm.subTime !== 0,
              btn_title: "取消",
              btn_title2: `确认${_vm.subTime || ""}`,
            },
            on: {
              "dialog-cancel": _vm.closeSubmitDialog,
              close: function ($event) {
                _vm.showNotAnwserTip = false
              },
              "dialog-ok": _vm.finish,
            },
          },
          [
            _c("div", { staticClass: "occupy-result" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v("您还有未作答的题目!"),
                ]),
                _c("div", { staticClass: "detail" }, [
                  _vm._v("您确认要交卷吗?"),
                ]),
              ]),
            ]),
          ]
        ),
        _c(
          "GlobalDialog",
          {
            attrs: {
              dialogVisible: _vm.showConfirmSubTip,
              title: "提示",
              width: "500px",
              show_close: false,
              showSecond: true,
              disabled2: _vm.subTime !== 0,
              btn_title: "取消",
              btn_title2: `确认${_vm.subTime || ""}`,
            },
            on: {
              "dialog-cancel": _vm.closeSubmitDialog,
              close: function ($event) {
                _vm.showConfirmSubTip = false
              },
              "dialog-ok": _vm.finish,
            },
          },
          [
            _c("div", { staticClass: "occupy-result" }, [
              _c("i", { staticClass: "iconfont icontishi" }),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "detail" }, [
                  _vm._v("您确认要交卷吗?"),
                ]),
              ]),
            ]),
          ]
        ),
        _c("detect-layout"),
        _c("div", { staticClass: "title" }, [
          _vm._v(
            " 试卷列表 " + _vm._s(_vm.isPractice ? "（考前练习题）" : "") + " "
          ),
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "paper-list",
            attrs: { "element-loading-text": "试卷列表加载中" },
          },
          _vm._l(_vm.papers, function (paper, key) {
            return _c(
              "div",
              { key: paper.positionUuid, staticClass: "paper-item" },
              [
                _c("div", { staticClass: "info" }, [
                  _c("div", { staticClass: "name" }, [
                    _vm._v(
                      " 卷 " +
                        _vm._s(key + 1) +
                        "：" +
                        _vm._s(paper.name) +
                        "  " +
                        _vm._s(
                          _vm.$store.state.jobInfo.partStartTimeType === 2
                            ? paper.startAt
                              ? `（开始时间：${paper.startAt.slice(-8)}）`
                              : ""
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                  _c("div", { staticClass: "attr" }, [
                    _c("span", [
                      _vm._v("试题："),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "17px", color: "#333" } },
                        [_vm._v(_vm._s(paper.questionCount) + " ")]
                      ),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("道"),
                      ]),
                    ]),
                    _c("span", [
                      _vm._v("已答："),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "20px", color: "#333" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.isPractice ? "--" : paper.answeredCount || 0
                            ) + " "
                          ),
                        ]
                      ),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("道"),
                      ]),
                    ]),
                    _c("span", [
                      _vm._v("时长："),
                      _c(
                        "span",
                        { staticStyle: { "font-size": "17px", color: "#333" } },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.isPractice
                                ? "--"
                                : Math.ceil(paper.partDuration / 60)
                            ) + " "
                          ),
                        ]
                      ),
                      _c("span", { staticStyle: { "font-size": "14px" } }, [
                        _vm._v("分钟"),
                      ]),
                    ]),
                    !_vm.$store.state.examInfo.isEvaluation
                      ? _c("span", [
                          _vm._v("分值："),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                "font-size": "17px",
                                color: "#333",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isPractice || paper.isQuestionnaire
                                    ? "--"
                                    : paper.partScore
                                ) + " "
                              ),
                            ]
                          ),
                          _c("span", { staticStyle: { "font-size": "14px" } }, [
                            _vm._v("分"),
                          ]),
                        ])
                      : _vm._e(),
                    _c(
                      "span",
                      [
                        _c(
                          "el-tooltip",
                          {
                            staticClass: "item",
                            attrs: {
                              effect: "dark",
                              content: "提交后不能修改",
                              placement: "bottom",
                            },
                          },
                          [
                            !paper.isEnableModifyAfterSubmit && !_vm.isPractice
                              ? _c("i", {
                                  staticClass: "iconfont iconNo_editor",
                                })
                              : _vm._e(),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                paper.status === 0
                  ? _c(
                      "el-button",
                      {
                        staticClass: "operate-btn",
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.startPart(paper)
                          },
                        },
                      },
                      [_vm._v("开始答题")]
                    )
                  : paper.status === 1
                  ? _c(
                      "el-button",
                      {
                        staticClass: "operate-btn",
                        attrs: { type: "primary", icon: "el-icon-edit" },
                        on: {
                          click: function ($event) {
                            return _vm.toQuestion(paper)
                          },
                        },
                      },
                      [_vm._v("已开始")]
                    )
                  : paper.status === 3
                  ? _c(
                      "el-button",
                      {
                        style: !paper.isEnableModifyAfterSubmit
                          ? "width: 118px;background-color: #b5b5b5; color: #fff"
                          : "width: 118px;background-color: rgba(203, 42, 29, 0.2); color: #cb2a1d",
                        attrs: {
                          type: "info",
                          plain: "",
                          disabled: !paper.isEnableModifyAfterSubmit,
                          icon: !paper.isEnableModifyAfterSubmit
                            ? "iconfont iconright-f"
                            : "iconfont iconxiangpi",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toQuestion(paper)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            !paper.isEnableModifyAfterSubmit
                              ? "已提交"
                              : "修改答案"
                          )
                        ),
                      ]
                    )
                  : paper.status === 2
                  ? _c(
                      "el-button",
                      {
                        staticClass: "operate-btn",
                        attrs: {
                          type: "primary",
                          icon: "iconfont iconxiangpi",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toQuestion(paper)
                          },
                        },
                      },
                      [_vm._v("修改答案")]
                    )
                  : _c(
                      "el-button",
                      {
                        staticClass: "operate-btn",
                        attrs: {
                          type: "primary",
                          icon: "iconfont iconxiangpi",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toPracticeQuestion(paper)
                          },
                        },
                      },
                      [_vm._v("开始答题")]
                    ),
              ],
              1
            )
          }),
          0
        ),
        !_vm.isPractice
          ? _c(
              "el-button",
              {
                directives: [
                  { name: "preventReClick", rawName: "v-preventReClick" },
                ],
                staticClass: "sub-btn",
                attrs: { type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.examFinish()
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.hasNextExam ? "交卷并作答下一场" : "我要交卷")
                ),
              ]
            )
          : _vm._e(),
        _vm.isPractice
          ? _c(
              "el-button",
              {
                staticClass: "sub-btn",
                attrs: { type: "primary" },
                on: { click: () => _vm.$router.push({ path: "/notice" }) },
              },
              [_vm._v("返回")]
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }